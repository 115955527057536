import ScriptsList from 'components/ScriptsList'
import {
    Container
} from "semantic-ui-react"

const ScriptsPage = () => {
    return (
        <div className='scripts-page'>
            <Container>
                <ScriptsList />
            </Container>
        </div>
    )
}

export default ScriptsPage