import {
    DragDropContext,
    Draggable,
    Droppable
} from "react-beautiful-dnd"
import {
    reorder,
    grid,
    getItemStyle,
    getListStyle,
    insert
} from "./dnd-helpers"
import _ from "lodash"
import { useState } from "react"
import {
    Input
} from "semantic-ui-react"

const CollectionItems = ({
    collection,
    updateCollection,
    allArtworks
}) => {
    const [searchArtworks, setSearchArtworks] = useState("")
    var objectIDs = collection.objectIDs
    var populatedObjects = objectIDs.map(id => {
        return _.find(allArtworks, p => p._id === id)
    }).filter(p => !_.isUndefined(p))
    return (
        <div className='collection-items'>
            <DragDropContext onDragEnd={(result) => {
                // if source is collection-items and destination is other-items remove item from collection
                if(result.source.droppableId === "collection-items" && result.destination.droppableId === "other-items") {
                    updateCollection({
                        ...collection,
                        objectIDs: objectIDs.filter(id => id !== result.draggableId)
                    })
                }
                // if source and destination both are collection-items, reorder collection items list
                if(result.source.droppableId === "collection-items" && result.destination.droppableId === "collection-items") {
                    updateCollection({
                        ...collection,
                        objectIDs: reorder(objectIDs, result.source.index, result.destination.index)
                    })
                }

                // if source is other items and destination is collection-items, add item to collection at index
                if(result.source.droppableId === "other-items" && result.destination.droppableId === "collection-items") {
                    updateCollection({
                        ...collection,
                        objectIDs: insert(objectIDs, result.destination.index, result.draggableId)
                    })
                }
            }}>
                <div style={{display: "flex"}}>
                    <div style={{paddingRight: 10}}>
                        <h3>In collection</h3>
                        <Droppable
                            droppableId="collection-items"
                            >
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    ref={droppableProvided.innerRef}
                                    className='droppable-div'
                                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                                    >
                                    {populatedObjects.map((item, index) => {
                                        return (
                                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => {
                                                    return (
                                                        <div
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                            style={getItemStyle(
                                                                draggableSnapshot.isDragging,
                                                                draggableProvided.draggableProps.style
                                                            )}
                                                            className='draggable-item'
                                                        >
                                                            <h3>{item.name}</h3>
                                                            <p>{item._id}</p>
                                                        </div>
                                                    )
                                                    
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div style={{paddingRight: 10}}>
                        <h3>Available</h3>
                        <Input placeholder="search..." size="mini" fluid
                            value={searchArtworks}
                            onChange={(e) => setSearchArtworks(e.target.value)}
                            />
                        <Droppable
                            droppableId="other-items"
                            >
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    ref={droppableProvided.innerRef}
                                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                                    className='droppable-div'
                                    >
                                    {allArtworks.filter(work => !objectIDs.includes(work._id))
                                        .filter(item => {
                                            if(searchArtworks.length === 0) {
                                                return true
                                            } else {
                                                return _.toLower(item.name).includes(_.toLower(searchArtworks))
                                            }
                                        })
                                        .map((item, index) => {
                                        return (
                                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => {
                                                    return (
                                                        <div
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                            style={getItemStyle(
                                                                draggableSnapshot.isDragging,
                                                                draggableProvided.draggableProps.style
                                                            )}
                                                            className='draggable-item'
                                                        >
                                                            <h3>{item.name}</h3>
                                                            <p>{item._id}</p>
                                                        </div>
                                                    )
                                                    
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </DragDropContext>
        </div>
    )
}

export default CollectionItems