import React, { useEffect, useContext, useState } from "react"
import {
    UserContext,
    apiUrl
} from "@dialectic-design/hyperobjects-user-context"
import {
    DataTable
} from "@dialectic-design/hyperobjects-entity-context"
import {
    Container,
    Button,
    Grid
} from "semantic-ui-react"
import { useHistory } from "react-router-dom"
import _ from "lodash"

const CollectionsPage = () => {
    const user = useContext(UserContext)
    const [collections, setCollections] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if(collections === false) {
            fetch(apiUrl + "/admin/collections", {
                headers: {
                    "Authorization": `Bearer ${user.token}`
                },
                credentials: "include"
            }).then(resp => {
                resp.json().then(data => {
                    console.log(data)
                    setCollections(data)
                })
            })
        }
    })
    return (
        <div className='collections-page'>
            <Container>
                <Grid columns={2} style={{marginBottom: 5}}>
                    <Grid.Column>
                        <h2>Collections</h2>
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            floated="right"
                            onPointerDown={() => {
                                history.push("/new-collection")
                            }}>
                            New collection
                        </Button>
                    </Grid.Column>
                </Grid>
                {_.isArray(collections) ? (
                    <DataTable
                    data={collections}
                    columns={[
                        "name",
                        "createdAt",
                        "_id",
                        "public"
                    ]}
                    link={(row) => `/collection/${row._id}`}
                    />
                ) : (
                    <p>Loading</p>
                )}
            </Container>
        </div>
    )
}

export default CollectionsPage