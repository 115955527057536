import React, { useEffect, useContext, useState } from "react"
import {
    UserContext,
    apiUrl
} from "@dialectic-design/hyperobjects-user-context"
import {
    DataTable
} from "@dialectic-design/hyperobjects-entity-context"
import {
    Container,
    Button,
    Grid
} from "semantic-ui-react"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import day from "dayjs"

const ArtworksPage = () => {
    const user = useContext(UserContext)
    const [artworks, setArtworks] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if(artworks === false) {
            fetch(apiUrl + "/admin/artworks", {
                headers: {
                    "Authorization": `Bearer ${user.token}`
                },
                credentials: "include"
            }).then(resp => {
                resp.json().then(data => {
                    console.log(data)
                    setArtworks(data)
                })
            })
        }
    })
    return (
        <div className='artworks-page'>
            <Container>
                <Grid columns={2} style={{marginBottom: 5}}>
                    <Grid.Column>
                        <h2>Artworks</h2>
                        <Button
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(artworks)
                                )}`}
                                download={`all-artworks-${day().format("YYYY-MM-DDTHH:mm")}.json`}
                            >
                            Download all
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            floated="right"
                            onPointerDown={() => {
                                history.push("/new-artwork")
                            }}>
                            New artwork
                        </Button>
                    </Grid.Column>
                </Grid>
                {_.isArray(artworks) ? (
                    <React.Fragment>
                        
                        <DataTable
                            search={true}
                            data={artworks}
                            columns={[
                                "name",
                                "createdAt",
                                "_id",
                                "public"
                            ]}
                            link={(row) => `/artwork/${row._id}`}
                            />
                    </React.Fragment>
                ) : (
                    <p>Loading</p>
                )}
            </Container>
        </div>
    )
}

export default ArtworksPage