import React, { useState, useEffect, useContext } from "react"
import {
    UserContext,
    apiUrl
} from "@dialectic-design/hyperobjects-user-context"
import {
    Container,
    Grid,
    Label,
    Tab,
    Checkbox,
    Input
} from "semantic-ui-react"
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import CollectionItems from "./CollectionItems"
import "./collection-editor.scss"
import _ from "lodash"
import GridEditor from "./GridEditor"
import dayjs from "dayjs"

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });
  



const CollectionEditor = ({ collection }) => {
    const user = useContext(UserContext)
    const [storingState, setStoringState] = useState("initial load")
    const [_collection, set_collection] = useState(false)
    const [updatingCollection, setUpdatingCollection] = useState(false)
    const [allArtworks, setAllArtworks] = useState(false)
    const [excerptTab, setExcerptTab] = useState("write")
    const [descriptionTab, setDescriptionTab] = useState("write")
    const [reloadMarkdownEditor, setReloadMarkdownEditor] = useState(false)

    useEffect(() => {
        if(_collection === false) {
            set_collection(collection)
        }
    })
    useEffect(() => {
        if(allArtworks === false) {
            fetch(apiUrl + "/admin/artworks", {
                headers: {
                    "Authorization": `Bearer ${user.token}`
                },
                credentials: "include"
            }).then(resp => {
                resp.json().then(data => {
                    setAllArtworks(data)
                })
            })
        }
    })
    useEffect(() => {
        if(reloadMarkdownEditor) {
            setTimeout(() => {
                setReloadMarkdownEditor(false)
            }, 10)
        }
    })
    if(allArtworks === false || _collection === false) {
        return (
            <p>Loading artworks</p>
        )
    }

    function updateCollection(updatedCollection) {
        console.log("collection to db: ",updatedCollection)
        set_collection(updatedCollection)
        setStoringState("updated collection, syncing to db.")
        if(updatingCollection === false) {
            setUpdatingCollection(true)
            fetch(apiUrl + `/admin/collection`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${user.token}`,
                    "Content-Type":"application/json"
                },
                credentials: "include",
                body: JSON.stringify(updatedCollection)
            }).then(resp => {
                setUpdatingCollection(false)
                if(resp.status === 200) {
                    setStoringState("successfully stored to db")
                    resp.json().then(respStructure => {
                        console.log("db response:", respStructure)
                        if(!_.isEqual(respStructure.cachedObjects, _collection.cachedObjects)) {
                            set_collection({
                                ..._collection,
                                cachedObjects: respStructure.cachedObjects
                            })
                        }
                    })
                } else {
                    console.log(resp)
                    setStoringState(`${resp.status} - error`)
                }
            })
        }
        
    }
    const editorHeight = _.clamp(window.innerHeight - 350, 200, 800)

    

    const textEditingPanes = [{
        menuItem: "Excerpt",
        render: () => <Tab.Pane>
            {reloadMarkdownEditor === false && (
                <ReactMde
                value={_collection.excerptMd}
                onChange={(newValue) => {
                    updateCollection({
                        ..._collection,
                        excerptMd: newValue
                    })
                }}
                selectedTab={excerptTab}
                onTabChange={setExcerptTab}
                generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                maxEditorHeight={editorHeight}
                minEditorHeight={editorHeight}
                initialEditorHeight={editorHeight}
                />
            )}
        </Tab.Pane>
    }, {
        menuItem: "Description",
        render: () => <Tab.Pane>
            {reloadMarkdownEditor === false && (
                <ReactMde
                    value={_collection.descriptionMd}
                    onChange={(newValue) => {
                        updateCollection({
                            ..._collection,
                            descriptionMd: newValue
                        })
                    }}
                    selectedTab={descriptionTab}
                    onTabChange={setDescriptionTab}
                    generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                    maxEditorHeight={editorHeight}
                    minEditorHeight={editorHeight}
                    initialEditorHeight={editorHeight}
                    />
            )}
        </Tab.Pane>
    }]
    
    return (
        <div className='collection-editor'>
            <Container>
                <div style={{display: "flex"}}>
                    <h1>
                        {collection.name}
                        <Label size="tiny" style={{position: "relative", top: -5, marginLeft: 20}} >
                            {storingState}
                        </Label>
                    </h1>
                    
                </div>
                <div className="ui form controls-row" style={{paddingLeft: 10, paddingTop: 10, paddingBottom: 10, display: "flex"}}>
                    <Checkbox
                        toggle
                        label="public"
                        size="tiny"
                        checked={_.get(_collection, "public", false)}
                        onChange={() => {
                            const newPublic = !_.get(_collection, "public", false)
                            updateCollection({
                                ..._collection,
                                public: newPublic
                            })
                        }}
                        />
                    <Input size="mini" label="ID"
                        value={`${_collection._id}`}
                        />
                    <Input size="mini" label="Data API"
                        value={`https://api.hyperobjects.design/collection/public/${_collection._id}`}
                        />
                    <Input size="mini" label="Data API"
                        value={`https://api.hyperobjects.design/collection/find/${_collection.name}`}
                        />
                    {window.location.hostname.includes("localhost") && (
                        <React.Fragment>
                        <Input size="mini" label="Localhost API"
                            value={`http://localhost:5000/collection/public/${_collection._id}`}
                            />
                        <Input size="mini" label="Localhost API"
                            value={`http://localhost:5000/collection/find/${_collection.name}`}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div style={{paddingBottom: 10}}>
                    <Input size="mini" label="Page Link"
                        value={_.get(_collection, "pageLink", "")}
                        placeholder="Main page where this collection is displayed..."
                        onChange={(e) => {
                            updateCollection({
                                ..._collection,
                                pageLink: e.target.value
                            })
                        }}
                        fluid
                        />
                </div>
                <Grid columns={2}>
                    <Grid.Column>
                        <h2>Collection items</h2>
                        <CollectionItems
                            collection={_collection}
                            allArtworks={allArtworks}
                            updateCollection={updateCollection}
                            />
                    </Grid.Column>
                    <Grid.Column>
                        <h2>Texts</h2>
                        <Tab panes={textEditingPanes} renderActiveOnly={true} onTabChange={() => setReloadMarkdownEditor(true)} />
                    </Grid.Column>
                </Grid>
            </Container>
            <GridEditor
                _collection={_collection}
                updateCollection={updateCollection}
                />
        </div>
    )
}

export default CollectionEditor