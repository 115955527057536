import React, { useState } from "react"
import {
    Checkbox
} from "semantic-ui-react"
import {
    generateCollectionPreview
} from "@dialectic-design/hyperobjects-art"
import MonacoEditor from '@monaco-editor/react'
import { invertedOverlayPrefix } from "utils/extras"

const CollectionPlaceholderCode = ({ collection }) => {
    const [invertOverlay, setInvertOverlay] = useState(false)
    var previewString = generateCollectionPreview(collection)
    var wrappedPreviewString = ""
    var invert_overlay = ""
    if(invertOverlay === true) {
        invert_overlay = invertedOverlayPrefix
    }

    wrappedPreviewString = `${invert_overlay}${previewString}`

    return (
        <div className='collection-placeholder-code'>
            <div className='controls'>
                <Checkbox
                    label="switch overlay styling"
                    toggle
                    checked={invertOverlay}
                    onChange={() => setInvertOverlay(!invertOverlay)}
                    />
            </div>
            <MonacoEditor
                defaultValue={wrappedPreviewString}
                value={wrappedPreviewString}
                automaticLayout={true}
                theme="vs-dark"
                height={window.innerHeight - 180}
                />
        </div>
    )
}

export default CollectionPlaceholderCode