import React, { useContext, useEffect, useState } from "react"
import {
    UserContext,
    apiUrl
} from "@dialectic-design/hyperobjects-user-context"
import {
    Loader,
    Tab
} from "semantic-ui-react"
import _ from "lodash"
import { useParams } from "react-router-dom"
import CollectionEditor from "components/CollectionEditor"
import CollectionPreview from "components/CollectionPreview"
import CollectionPlaceholderCode from "components/CollectionPlaceholderCode"

const CollectionPage = () => {
    const user = useContext(UserContext)
    const [collection, setCollection] = useState(false)
    const { id } = useParams()
    useEffect(() => {
        if(collection === false) {
            fetch(apiUrl + `/admin/collection/${id}`, {
                headers: {
                    "Authorization": `Bearer ${user.token}`
                },
                credentials: "include"
            }).then(resp => {
                if(resp.status === 200) {
                    resp.json().then(data => {
                        setCollection(data)
                    })
                } else {
                    console.log("fetch error: ", resp)
                }
            })
        }
    })

    var panes = []

    if(_.isObject(collection)) {
        panes = [
            {
                menuItem: "Editor",
                render: () => <Tab.Pane><CollectionEditor collection={collection} setCollection={setCollection} /></Tab.Pane>
            },
            {
                menuItem: "Preview",
                render: () => <Tab.Pane><CollectionPreview collection={collection} /></Tab.Pane>
            },
            {
                menuItem: "HTML",
                render: () => <Tab.Pane><CollectionPlaceholderCode collection={collection} /></Tab.Pane>
            }
        ]
    }

    return (
        <div className='collection-page'>
            {collection === false && (
                <Loader />
            )}
            {_.isObject(collection) && (
                <Tab panes={panes} />
            )}
        </div>
    )
}

export default CollectionPage