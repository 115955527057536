const initialState = {
    users: false,
    patterns: false,
    scripts: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        default:
            return state
    }
}

export {
    initialState,
    reducer
}