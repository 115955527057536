import UsersList from "components/UsersList"

const UsersPage = () => {
    return (
        <div className='users-page'>
            <h3>Users</h3>
            <UsersList
                />
        </div>
    )
}

export default UsersPage