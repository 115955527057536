import React, { useContext, useState } from 'react'
import {
    Menu,
    Button,
    Modal,
    Segment
} from 'semantic-ui-react'
import './main-menu.scss'
import {
    UserContext,
    LoginForm,
} from '@dialectic-design/hyperobjects-user-context'
import {
    Link
} from 'react-router-dom'


const MainMenu = () => {
    const [showModal, setShowModal] = useState(false)
    const user = useContext(UserContext)
    return (
        <>
        <Modal
            open={showModal === 'log-in'}
            onClose={() => setShowModal(false)}
            closeIcon
            size='mini'
            >
            <Modal.Content>
                <LoginForm
                    />
            </Modal.Content>
        </Modal>
        <Segment inverted className='main-menu'>
        <Menu inverted>
            <Link to='/'>
            <Menu.Item>
                Hyperobjects admin
            </Menu.Item>
            </Link>
            
            {user.authenticated ? (
                <React.Fragment>
                    <Link to='/users'><Menu.Item name='Users' /></Link>
                    <Link to='/patterns'><Menu.Item name='Patterns' /></Link>
                    <Link to='/scripts'><Menu.Item name='Scripts' /></Link>
                    <Link to='/artworks'><Menu.Item name='Artworks' /></Link>
                    <Link to='/collections'><Menu.Item name='Collections' /></Link>
                    <Link to='/settings'><Menu.Item name='Settings' /></Link>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                inverted basic
                                onPointerDown={() => user.actions.userLogOut()}
                                size="mini"
                                >
                                Log out
                            </Button>
                        </Menu.Item>
                        <Menu.Item>Account</Menu.Item>
                    </Menu.Menu>
                </React.Fragment>
            ) : (
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Button
                            inverted basic
                            onPointerDown={() => setShowModal('log-in')}
                            size="mini">
                            Log in
                        </Button>
                    </Menu.Item>
                </Menu.Menu>
            )}
        </Menu>
        </Segment>
        </>
    )
}

export default MainMenu