import React from "react"
import { Tab } from "semantic-ui-react"
import _ from "lodash"
import {
    EditableList
} from "@dialectic-design/hyperobjects-entity-context"


const Images = ({_artwork, updateArtwork}) => {

    const imageAttrs = [
        "imageUrls",
        "squareImagePreviewUrls",
        "landscapeImagePreviewUrls",
        "portraitImagePreviewUrls"
    ]
    var menuTitles = [
        "High Res",
        "Square",
        "Landscape",
        "Portrait"
    ]

    var panes = imageAttrs.map((attr, i) => {
        return {
            menuItem: menuTitles[i],
            render: () => <Tab.Pane>
                <EditableList
                    items={_artwork[attr]}
                    callback={newUrls => {
                        var newWork = _artwork
                        newWork[attr] = newUrls
                        updateArtwork(newWork)
                    }}
                    />
            </Tab.Pane>
        }
    })

    return (
        <div className='images' style={{marginTop: 20}}>
            <h3>Images</h3>
            <Tab panes={panes} />
        </div>
    )
}

export default Images