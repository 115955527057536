export const invertedOverlayPrefix = `{% put styleWrapper %}
<style>
.ui.menu.overlay-menu a.item {
    color: black;
    background-image: linear-gradient(to right, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8) 50%);
}

.ui.menu.overlay-menu a.item:hover {
    color: white;
}


.slick-arrow:hover {
    background: rgba(220,220,220,0.1) !important;
}

.slick-arrow::before {
    color: rgba(0,0,0,0.5) !important;
}
.slick-arrow:hover::before {
    color: rgba(0,0,0,1) !important;
}

.overlay {
    color: black;
}
</style>
{% endput %}
`