import React, { useContext, useEffect, useState } from "react"
import {
    Grid,
    Form,
    Input,
    Checkbox,
    Select,
    Label,
    Menu,
    Tab,
    Button
} from "semantic-ui-react"
import {
    UserContext,
    apiUrl
  } from '@dialectic-design/hyperobjects-user-context'

import _ from "lodash"
import {
    EditableList
} from "@dialectic-design/hyperobjects-entity-context"
import Images from "./Images"
import Texts from "./Texts"


// https://github.com/andrerpena/react-mde

const ArtworkEditor = ({ artwork, setArtwork }) => {
    const user = useContext(UserContext)
    const [storingState, setStoringState] = useState("initial load")
    const [_artwork, set_artwork] = useState(false)
    const [updatingArtwork, setUpdatingArtwork] = useState(false)
    const [newName, setNewName] = useState(_.get(artwork, "name", "new-name"))

    useEffect(() => {
        if(_artwork === false) {
            set_artwork(artwork)
        }
    })
    
    function updateArtwork(updatedArtwork) {
        set_artwork(updatedArtwork)
        setArtwork(updatedArtwork)
        setStoringState("updated artwork, syncing to db.")
        if(updatingArtwork === false) {
            setUpdatingArtwork(true)
            fetch(apiUrl + `/admin/artwork`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${user.token}`,
                    "Content-Type":"application/json"
                },
                credentials: "include",
                body: JSON.stringify(updatedArtwork)
            }).then(resp => {
                setUpdatingArtwork(false)
                if(resp.status === 200) {
                    setStoringState("successfully stored to db")
                } else {
                    console.log(resp)
                    setStoringState(`${resp.status} - error`)
                }
            })
        }
        
    }
    if(_artwork === false) {
        return null
    }
    
    return (
        <div className='artwork-editor'>
            <Grid columns={2}>
                <Grid.Column>
                    <div style={{display: 'flex'}}>
                        <h1>
                            {_artwork.name}
                        </h1>
                        
                        <div className="ui form" style={{paddingLeft: 10, paddingTop: 10}}>
                            <Checkbox
                                toggle
                                label="public"
                                size="tiny"
                                checked={_.get(_artwork, "public", false)}
                                onChange={() => {
                                    const newPublic = !_.get(_artwork, "public", false)
                                    updateArtwork({
                                        ..._artwork,
                                        public: newPublic
                                    })
                                }}
                                />
                        </div>
                    </div>
                    <Label size="tiny" >
                        {storingState}
                    </Label>
                    <div style={{paddingTop: 10}}>
                        <Input
                            fluid
                            labelPosition="right"
                            size="mini"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            className={_artwork.name !== newName ? "changed" : ""}
                            label={<Button onPointerDown={() => updateArtwork({..._artwork, name: newName})}>Rename</Button>}
                            />
                    </div>
                    <h3>API urls</h3>
                    <div style={{paddingTop: 10}}>
                        <Input size="mini" label="ID" labelPosition="right"
                            value={`${_artwork._id}`}
                            fluid
                            />
                    </div>
                    <div style={{paddingTop: 10}}>
                        <Input size="mini" label="Data API" labelPosition="right"
                            value={`https://api.hyperobjects.design/artwork/public/${_artwork._id}`}
                            fluid
                            />
                    </div>
                    
                    {window.location.hostname.includes("localhost") && (
                    <div style={{paddingTop: 10}}>
                        <Input size="mini" label="Localhost API" labelPosition="right"
                            value={`http://localhost:5000/artwork/public/${_artwork._id}`}
                            fluid
                            />
                    </div>
                    )}
                    <h3>Metadata</h3>
                    <div className='ui form'>
                        <Form.Field>
                            <label>Page url</label>
                            <Input size="mini"
                                value={_.get(_artwork, "pageLink", "")}
                                onChange={(e) => {
                                    updateArtwork({
                                        ..._artwork,
                                        pageLink: e.target.value
                                    })
                                }}
                                />
                        </Form.Field>
                        <Form.Field>
                            <label>Suggested url</label>
                            <Input size="mini"
                                value={`/artwork/${_artwork.name.replace(/\s+/g, '-').toLowerCase()}`}
                                />
                        </Form.Field>
                        <Form.Field>
                            <label>Author name</label>
                            <Input size="mini"
                                value={_.get(_artwork, "authorName", "")}
                                onChange={(e) => {
                                    updateArtwork({
                                        ..._artwork,
                                        authorName: e.target.value
                                    })
                                }}
                                />
                        </Form.Field>
                        <Form.Field>
                            <label>Author link</label>
                            <Input size="mini"
                                value={_.get(_artwork, "authorLink", "")}
                                onChange={(e) => {
                                    updateArtwork({
                                        ..._artwork,
                                        authorLink: e.target.value
                                    })
                                }}
                                />
                        </Form.Field>
                    </div>
                    <Images _artwork={_artwork} updateArtwork={updateArtwork} />
                    
                    <h3>Video urls</h3>
                    <EditableList
                        items={_artwork.videoUrls}
                        callback={newUrls => {
                            updateArtwork({
                                ..._artwork,
                                videoUrls: newUrls
                            })
                        }}
                        />
                    <h3>Tags</h3>
                    <EditableList
                        items={_artwork.tags}
                        callback={newItems => {
                            console.log("new tags: ", newItems)
                            updateArtwork({
                                ..._artwork,
                                tags: newItems
                            })
                        }}
                        />
                    <h3>Collections</h3>
                    <EditableList
                        items={_artwork.collections}
                        callback={newItems => {
                            updateArtwork({
                                ..._artwork,
                                collections: newItems
                            })
                        }}
                        />
                    
                </Grid.Column>
                <Grid.Column>
                    <Texts _artwork={_artwork} updateArtwork={updateArtwork} />
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default ArtworkEditor