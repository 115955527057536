import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { reducer, initialState } from './reducer'
import { UserContext } from '@dialectic-design/hyperobjects-user-context'

const AdminContext = createContext()

const AdminProvider = ({ children }) => {
    const user = useContext(UserContext)
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <AdminContext.Provider value={state}>
            {children}
        </AdminContext.Provider>
    )
}

export {
    AdminContext,
    AdminProvider
}