import React, { useState } from "react"
import {
    Tab,
    Table,
    Button,
    Container,
    Grid,
    Dropdown,
    Label
} from "semantic-ui-react"
import { GridLayout, MediaViewer } from "@dialectic-design/hyperobjects-art"
import _ from "lodash"

function createGridItem(item, image, items) {
    const existingGridIDs = items.map(p => p.id)
    var newId = item._id
    if(existingGridIDs.includes(newId)) {
        var increment = 1
        var newId = `${item._id}-${increment}`
        while(existingGridIDs.includes(newId)) {
            increment += 1
            newId = `${item._id}-${increment}`
        }

    }
    return {
        id: newId,
        name: item.name,
        image: image,
        link: _.get(item, "pageLink", "")
    }
}

const GridEditor = ({ _collection, updateCollection }) => {
    const [selectedItem, setSelectedItem] = useState(false)
    const items = _.get(_collection, "cachedObjects", [])
    var gridLayouts = _.get(_collection, "gridLayouts", {})
    var gridItems = _.get(gridLayouts, "items", [])
    var layouts = _.get(gridLayouts, "layouts", {})

    var selectedItemImages = []
    if(selectedItem) {
        selectedItemImages = selectedItemImages.concat(_.get(selectedItem, "imageUrls", []))
        selectedItemImages = selectedItemImages.concat(_.get(selectedItem, "squareImagePreviewUrls", []))
        selectedItemImages = selectedItemImages.concat(_.get(selectedItem, "landscapeImagePreviewUrls", []))
        selectedItemImages = selectedItemImages.concat(_.get(selectedItem, "portraitImagePreviewUrls", []))
    }

    const panes = [
        {
            menuItem: "Grid",
            render: () => <Tab.Pane>
                <GridLayout
                    items={gridItems}
                    layouts={layouts}
                    editable={true}
                    layoutsCallback={(newLayouts) => {
                        updateCollection({
                            ..._collection,
                            gridLayouts: {
                                ...gridLayouts,
                                layouts: newLayouts
                            }
                        })
                    }}
                    />
            </Tab.Pane>
        },{
            menuItem: "Items",
            render: () => <Tab.Pane>
                <Grid columns={2} style={{minHeight: 500}}>
                    <Grid.Column width={10}>
                        <Button onPointerDown={() => { updateCollection({..._collection, gridLayouts: false}) }}>Reset</Button>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Item</Table.HeaderCell>
                                    <Table.HeaderCell>Grid ID</Table.HeaderCell>
                                    <Table.HeaderCell>Image</Table.HeaderCell>
                                    <Table.HeaderCell>Controls</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {gridItems.map((item, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.image.slice(-20)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button size="tiny"
                                                    onPointerDown={() => {
                                                        updateCollection({
                                                            ..._collection,
                                                            gridLayouts: {
                                                                ...gridLayouts,
                                                                items: gridItems.filter((p, j) => j !== i)
                                                            }
                                                        })
                                                    }}
                                                    >
                                                    Remove
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <h3>Collection items</h3>
                        <Dropdown
                            pladeholder="Select an item"
                            fluid
                            selection
                            search
                            onChange={(e, {value}) => {
                                setSelectedItem(_.find(items, p => p.name === value))
                            }}
                            options={items.map(p => {
                                return {
                                    key: p.name,
                                    text: p.name,
                                    value: p.name,
                                }
                            })}
                            />
                        {selectedItem && (
                            <React.Fragment>
                                <h4>{selectedItem.name}</h4>
                                <Table size="small" columns={3}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>IMG</Table.HeaderCell>
                                            <Table.HeaderCell collapsing={true} >Add</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {selectedItemImages.map((image, i) => {
                                            const extension = _.last(_.split(image, "."))
                                            console.log(extension)
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell>
                                                        <div className='add-table-media-container' style={{width: 230, height: 80, overflow: "hidden"}}>
                                                            <Label color="black">
                                                                {extension}
                                                            </Label>
                                                            <MediaViewer
                                                                url={image}
                                                                />
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell collapsing={true}>
                                                        <Button size="tiny"
                                                            onPointerDown={() => {
                                                                updateCollection({
                                                                    ..._collection,
                                                                    gridLayouts: {
                                                                        ...gridLayouts,
                                                                        items: gridItems.concat([createGridItem(selectedItem, image, gridItems)])
                                                                    }
                                                                })
                                                            }}
                                                            >
                                                            Add
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </React.Fragment>
                        )}
                    </Grid.Column>
                </Grid>
                
            </Tab.Pane>
        }
    ]
    return (
        <Container style={{marginTop: 40, marginBottom: 100, minHeight: 600}}>
            <h2>Grid</h2>
            <Tab panes={panes} />
        </Container>
    )
}

export default GridEditor