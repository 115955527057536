import React, { useContext, useEffect, useState } from "react"
import {
    UserContext,
    apiUrl
} from "@dialectic-design/hyperobjects-user-context"
import {
    Container,
    Loader,
    Tab
} from "semantic-ui-react"
import ArtworkEditor from "components/ArtworkEditor"
import ArtworkPreview from "components/ArtworkPreview"
import ArtworkPlaceholderCode from "components/ArtworkPlaceholderCode"
import { useParams } from "react-router-dom"
import _ from "lodash"
const ArtworkPage = () => {
    const user = useContext(UserContext)
    const [artwork, setArtwork] = useState(false)
    const { id } = useParams()
    useEffect(() => {
        if(artwork === false) {
            setArtwork("loading")
            fetch(apiUrl + `/admin/artwork/${id}`, {
                headers: {
                    "Authorization": `Bearer ${user.token}`
                },
                credentials: "include"
            }).then(resp => {
                resp.json().then(data => {
                    setArtwork(data)
                })
            })
        }
    })
    var panes = []
    if(_.isObject(artwork)) {
        panes = [
            {
                menuItem: "Editor",
                render: () => <Tab.Pane><ArtworkEditor artwork={artwork} setArtwork={setArtwork} /></Tab.Pane>
            },
            {
                menuItem: "Preview",
                render: () => <Tab.Pane><ArtworkPreview artwork={artwork} /></Tab.Pane>
            },
            {
                menuItem: "HTML",
                render: () => <Tab.Pane><ArtworkPlaceholderCode artwork={artwork} /></Tab.Pane>
            }
        ]
    }

    return (
        <div className="artwork-page">
            {(artwork === false || artwork === "loading")&& (
                <Loader />
            )}
            {_.isObject(artwork) && (
                <Tab panes={panes} />
            )}
        </div>
    )
}

export default ArtworkPage