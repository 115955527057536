import React from "react"
import { CollectionPage } from "@dialectic-design/hyperobjects-art"

const CollectionPreview = ({
    collection
}) => {
    return (
        <div className='collection-preview'>
            <CollectionPage collectionId={collection._id} />
        </div>
    )
}

export default CollectionPreview