import React, { useState, useContext } from "react"
import {
    Container,
    Form,
    Input,
    Button,
    Message
} from "semantic-ui-react"
import {
    UserContext,
    apiUrl
  } from '@dialectic-design/hyperobjects-user-context'
import _ from "lodash"

const NewCollectionPage = () => {
    const user = useContext(UserContext)
    const [newName, setNewName] = useState("")
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    return (
        <div className="new-collection-page">
            <Container>
            <h2>New collection</h2>
            {success ? (
                <Message positive>
                    Collection successfully created.
                </Message>
            ) : (
                <div className='ui form'>
                    <Form.Field>
                        <label>Collection name</label>
                        <Input
                            placeholder="collection name..."
                            value={newName}
                            onChange={(e) => {
                                setNewName(e.target.value)
                                if(error === "Set a name." && e.target.value.length > 0) {
                                    setError(false)
                                }
                            }}
                            />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            onPointerDown={() => {
                                if(newName.length === 0) {
                                    setError("Set a name.")
                                } else {
                                    fetch(apiUrl + `/admin/collection`, {
                                        method: "POST",
                                        headers: {
                                            "Authorization": `Bearer ${user.token}`,
                                            "Content-Type":"application/json"
                                        },
                                        credentials: "include",
                                        body: JSON.stringify({
                                            name: newName
                                        })
                                    }).then(resp => {
                                        if(resp.status === 200) {
                                            setSuccess(true)
                                        } else if(resp.status === 400) {
                                            resp.json().then(data => {
                                                console.log(data)
                                                setError(_.get(data, "message", 'unknown error'))
                                            })
                                        } else {
                                            console.log(resp)
                                            setError(`connection error: ${resp.status}`)
                                        }
                                    })
                                }
                            }}
                            >
                            Create
                        </Button>
                    </Form.Field>
                    {error && (
                        <Message negative>
                            {error}
                        </Message>
                    )}
                </div>
            )}
            
            </Container>
        </div>
    )
}

export default NewCollectionPage