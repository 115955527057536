import React, { useEffect, useContext, useState } from "react"
import {
    UserContext,
    apiUrl
} from "@dialectic-design/hyperobjects-user-context"
import {
    Table,
    Button
} from "semantic-ui-react"
import _ from "lodash"
import day from "dayjs"

const UsersList = () => {
    const user = useContext(UserContext)
    const [users, setUsers] = useState(false)
    useEffect(() => {
        if(users === false) {
            fetch(apiUrl + '/admin/users', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                credentials: 'include'
            }).then(resp => {
                resp.json().then(data => {
                    setUsers(data)
                })
            })
        }
    })
    console.log(users)
    if(!_.isArray(users)) {
        return (
            <p>Loading users list</p>
        )
    }
    var keys = _.keys(_.get(users, 0, {}))
    return (
        <div className='users-list'>
            <Button
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(users)
                    )}`}
                    download={`all-users-${day().format("YYYY-MM-DDTHH:mm")}.json`}
                >
                Download all
            </Button>
            <Table>
                <Table.Header>
                    {keys.map(key => {
                        return (
                            <Table.HeaderCell>{key}</Table.HeaderCell>
                        )
                    })}
                </Table.Header>
                <Table.Body>
                    {users.map(_user => {
                        return (
                            <Table.Row key={_user._id}>
                                {keys.map(key => {
                                    return (
                                        <Table.Cell>{_.toString(_user[key])}</Table.Cell>
                                    )
                                })}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </div>
    )
}

export default UsersList