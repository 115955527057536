import React, { useState, useEffect } from "react"
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Tab } from "semantic-ui-react"

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });

const Texts = ({ _artwork, updateArtwork }) => {
    const [excerptTab, setExcerptTab] = useState("write")
    const [descriptionTab, setDescriptionTab] = useState("write")
    const [reloadMarkdownEditor, setReloadMarkdownEditor] = useState(false)
    const editorHeight = window.innerHeight - 350
    useEffect(() => {
        if(reloadMarkdownEditor) {
            setTimeout(() => {
                setReloadMarkdownEditor(false)
            }, 10)
        }
    })
    const textEditingPanes = [{
        menuItem: "Excerpt",
        render: () => <Tab.Pane>
            {reloadMarkdownEditor === false && (
                <ReactMde
                    value={_artwork.excerptMd}
                    onChange={(newValue) => {
                        updateArtwork({
                            ..._artwork,
                            excerptMd: newValue
                        })
                    }}
                    selectedTab={excerptTab}
                    onTabChange={setExcerptTab}
                    generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                    maxEditorHeight={editorHeight}
                    minEditorHeight={editorHeight}
                    initialEditorHeight={editorHeight}
                    />
            )}
            
        </Tab.Pane>
    }, {
        menuItem: "Description",
        render: () => <Tab.Pane>
            {reloadMarkdownEditor === false && (
                <ReactMde
                    value={_artwork.descriptionMd}
                    onChange={(newValue) => {
                        updateArtwork({
                            ..._artwork,
                            descriptionMd: newValue
                        })
                    }}
                    selectedTab={descriptionTab}
                    onTabChange={setDescriptionTab}
                    generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                    maxEditorHeight={editorHeight}
                    minEditorHeight={editorHeight}
                    initialEditorHeight={editorHeight}
                    />
            )}
        </Tab.Pane>
    }]
    return (
        <Tab
            panes={textEditingPanes}
            renderActiveOnly={true}
            onTabChange={() => setReloadMarkdownEditor(true)}
            />
                    
    )
}

export default Texts