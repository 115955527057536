import React, { useState, useEffect } from "react"
import { apiUrl } from "@dialectic-design/hyperobjects-user-context"
import { generatePreview } from "@dialectic-design/hyperobjects-art"
import MonacoEditor from '@monaco-editor/react'
import { Checkbox, Button } from "semantic-ui-react"
import "./artwork-placeholder-code.scss"
import { invertedOverlayPrefix } from "utils/extras"

const ArtworkPlaceholderCode = ({ artwork }) => {
    const [previewString, setPreviewString] = useState(false)
    const [invertOverlay, setInvertOverlay] = useState(false)
    useEffect(() => {
        if(previewString === false) {
            setPreviewString("fetching")
            var primaryCollection = artwork.collections[0]
            fetch(`${apiUrl}/collection/find/${primaryCollection}`).then(resp => {
                if(resp.status === 200) {
                    resp.json().then(collection => {
                        console.log(collection)
                        console.log('generating preview')
                        var previewString = generatePreview(artwork, collection)
                        console.log(previewString)
                        setPreviewString(previewString)
                    })
                } else {
                    console.log("error fetching collection: ", resp)
                }
            })
        }
        
    })
    console.log(previewString)

    var wrappedPreviewString = ""
    var invert_overlay = ""
    if(invertOverlay === true) {
        invert_overlay = invertedOverlayPrefix
    }

    wrappedPreviewString = `${invert_overlay}${previewString}`

    return (
        <div className='artwork-placeholder-code'>
            <div className='controls'>
                <Checkbox
                    label="switch overlay styling"
                    toggle
                    checked={invertOverlay}
                    onChange={() => setInvertOverlay(!invertOverlay)}
                    />
            </div>
            <MonacoEditor
                defaultValue={wrappedPreviewString}
                value={wrappedPreviewString}
                automaticLayout={true}
                theme="vs-dark"
                height={window.innerHeight - 180}
                />
        </div>
    )
}

export default ArtworkPlaceholderCode