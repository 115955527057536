import React, { useEffect, useContext, useState } from 'react'
import {
    UserContext,
    apiUrl
  } from '@dialectic-design/hyperobjects-user-context'
import {
    DataTable
} from "@dialectic-design/hyperobjects-entity-context"
import "@dialectic-design/hyperobjects-entity-context/dist/index.css"
import {
    Button,
    Divider
} from "semantic-ui-react"
import day from "dayjs"
import { useHistory } from "react-router-dom";

const PatternsList = () => {
    const user = useContext(UserContext)
    const [patterns, setPatterns] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if(patterns === false) {
            fetch(apiUrl + '/admin/patterns', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                credentials: 'include'
            }).then(resp => {
                resp.json().then(data => {
                    console.log(data)
                    setPatterns(data)
                })
            })
        }
    })
    return (
        <div className='patterns-list'>
            {patterns && (
                <React.Fragment>
                <h2>Scripts</h2>
                <Button
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(patterns)
                      )}`}
                      download={`all-patterns-${day().format("YYYY-MM-DDTHH:mm")}.json`}
                    >
                    Download all
                </Button>
                <Button
                    floated="right"
                    onPointerDown={() => {
                        history.push("/new-pattern")
                    }}
                    >
                    New pattern
                </Button>
                <Divider />
                <DataTable
                    data={patterns}
                    columns={[
                        "name",
                        "updatedAt",
                        "createdAt",
                        "_id"
                    ]}
                    search={true}
                    link={(row) => `/pattern/${row._id}`}
                    />
                </React.Fragment>
            )}
            
        </div>
    )
}

export default PatternsList