import React, { useEffect } from "react"
import { ArtworkPage } from "@dialectic-design/hyperobjects-art"
import "@dialectic-design/hyperobjects-art/dist/index.css"

const ArtworkPreview = ({
    artwork
}) => {
    console.log(artwork)

    return (
        <div className='artwork-preview'>
            <ArtworkPage artworkId={artwork._id} />
        </div>
    )
}

export default ArtworkPreview