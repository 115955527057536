import PatternsList from "components/PatternsList"
import {
    Container
} from "semantic-ui-react"

const PatternsPage = () => {
    return (
        <div className='patterns-page'>
            <Container>
                <PatternsList />
            </Container>
        </div>
    )
}

export default PatternsPage