import React, { useContext, useEffect } from 'react'
import {
  UserProvider,
  UserContext,
  LoginForm
} from '@dialectic-design/hyperobjects-user-context'
import '@dialectic-design/hyperobjects-user-context/dist/index.css'
import {
  AdminProvider
} from './store/store'

import MainMenu from 'components/MainMenu'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import MainPage from './containers/MainPage'
import SettingsPage from 'containers/SettingsPage'
import UsersPage from './containers/UsersPage'

import PatternsPage from './containers/PatternsPage'

import ScriptsPage from './containers/ScriptsPage'
import ScriptPage from "./containers/ScriptPage"
import NewScriptPage from 'containers/NewScriptPage'

import ArtworksPage from "containers/ArtworksPage"
import NewArtworkPage from "containers/NewArtworkPage"
import ArtworkPage from "containers/ArtworkPage"

import CollectionsPage from "containers/CollectionsPage"
import CollectionPage from "containers/CollectionPage"
import NewCollectionPage from "containers/NewCollectionPage"

import './App.scss';

function App() {
  return (
    <div className="App">
      <UserProvider>
        <HyperobjectsAdminApp />
      </UserProvider>
    </div>
  );
}

const HyperobjectsAdminApp = () => {
  const user = useContext(UserContext)
  useEffect(() => {
    if(user.authenticated && user.role !== 'Admin') {
      user.actions.userLogOut()
    }
  })
  return (
    <div className='app'>
      <Router>
      {user.authenticated && user.role === 'Admin' ? (
        <React.Fragment>
        <MainMenu />
        <div className='page-container'>
        <AdminProvider>
          <Switch>
            <Route path='/users'>
              <UsersPage />
            </Route>
            <Route path='/patterns'>
              <PatternsPage />
            </Route>
            
            <Route path='/scripts'>
              <ScriptsPage />
            </Route>
            <Route path="/script/:id">
              <ScriptPage />
            </Route>
            <Route path="/new-script">
              <NewScriptPage />
            </Route>

            <Route path="/artworks">
              <ArtworksPage />
            </Route>
            <Route path="/new-artwork">
              <NewArtworkPage />
            </Route>
            <Route path="/artwork/:id">
              <ArtworkPage />
            </Route>

            <Route path="/collections">
              <CollectionsPage />
            </Route>
            <Route path="/collection/:id">
              <CollectionPage />
            </Route>
            <Route path="/new-collection">
              <NewCollectionPage />
            </Route>

            <Route path='/settings'>
              <SettingsPage />
            </Route>
            <Route path='/'>
              <MainPage />
            </Route>
          </Switch>
        </AdminProvider>
        </div>
        </React.Fragment>
      ) : (
        <div className='ui container login'>
          <div className='main-login-form'>
          <LoginForm />
          </div>
        </div>
      )}
      </Router>
    </div>
  )
}

export default App;
