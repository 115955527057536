import React, { useState, useEffect, useContext } from "react"
import {
    useParams
} from "react-router-dom"
import {
    UserContext,
    apiUrl
  } from '@dialectic-design/hyperobjects-user-context'
import _ from "lodash"
import {
    Container
} from "semantic-ui-react"

const ScriptPage = () => {
    const user = useContext(UserContext)
    const [script, setScript] = useState(false)
    const { id } = useParams()
    useEffect(() => {
        if(script === false) {
            setScript("loading")
            fetch(apiUrl + `/admin/script/${id}`, {
                headers: {
                    "Authorization": `Bearer ${user.token}`
                },
                credentials: "include"
            }).then(resp => {
                resp.json().then(data => {
                    setScript(data)
                })
            })
        }
    })
    return (
        <div className='script-page'>
            <Container>
                {script === false && (
                    <p>Initialize loading</p>
                )}
                {script === "loading" && (
                    <p>Loading script</p>
                )}
                {_.isObject(script) && (
                    <p>{script.name}</p>
                )}
            </Container>
        </div>
    )
}

export default ScriptPage