
const MainPage = () => {
    return (
        <div className='main-page'>
            <h1>Main page</h1>
            
        </div>
    )
}

export default MainPage